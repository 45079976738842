export default {
    props: {
        item: { type: Object },
        initialItem: { type: Object },
        services: {type: Object},
    },
    watch: {
        item: {
            handler(newVal) {
                this.$parent.hasUnsavedChanges = this.compareObjects(newVal, this.initialItem)
            },
            deep: true
        }
    },
    methods: {
        removeDomain(index) {
            this.item.domains.splice(index, 1)
        },
        addDomain() {
            this.item.domains.push('')
        },
        async save() {
            var isValid = await this.$refs.observer.validate()
            if (isValid) {
                this.services.updateValidDomain({ domains: this.item.domains})
            }
        },
        compareObjects(obj1, obj2) {
            return JSON.stringify(obj1) !== JSON.stringify(obj2)
        }
    },
}