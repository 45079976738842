import { AUTH_ACTIONS } from '../../../../store/actions'
import {getUserType} from '../../../../misc/auth'
export default {
    props: {
        item: { type: Object },
        initialItem: { type: Object },
        mKey: { type: Number },
        services: {type: Object}
    },
    data() {
        return {
            dataPhoto: null,
            userType: getUserType(),
        }
    },
    watch: {
        mKey() {
            this.$nextTick(()=>{
                if (this.item.info.profile_photo) {
                    this.dataPhoto = 'data:image;base64,' + this.item.info.profile_photo
                }
            })
        },
        item: {
            handler(newVal) {
                this.$parent.hasUnsavedChanges = this.compareObjects(newVal, this.initialItem)
            },
            deep: true
        }
    },
    methods: {
        async save() {
            var isValid = await this.$refs.observer.validate()
            if (isValid) {
                let formData = new FormData()

                formData.append('address1', this.item.info.add1 ? this.item.info.add1.trim() : '')
                formData.append('address2', this.item.info.add2 ? this.item.info.add2.trim() : '')
                formData.append('city', this.item.info.city ? this.item.info.city.trim() : '')
                formData.append('state', this.item.info.state ? this.item.info.state.trim() : '')
                formData.append('zipCode', this.item.info.zip ? this.item.info.zip.trim() : '')
                formData.append('profilePhoto', this.item.info.profile_photo)

                this.services.updateInfo(formData).then(resp => {
                    if (!resp.error) {
                        this.$store.dispatch(AUTH_ACTIONS.GET_ORGANIZATION_INFO)
                    }
                })
            }
        },

        renderFileImg() {
            let extAllow = ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/bmp', 'image/ifif', 'image/pjpeg', 'image/pjp']
            let img = this.$refs.fileimg.files[0]
            if (!extAllow.includes(img.type)) {
                return
            }
            let reader = new FileReader()

            reader.readAsDataURL(img)
            reader.onload = e => {
                this.dataPhoto = e.target.result
                if (this.dataPhoto) {
                    this.item.info.profile_photo = img
                }
            }
        },
        compareObjects(obj1, obj2) {
            return JSON.stringify(obj1) !== JSON.stringify(obj2)
        }
    }
}