import _ from 'lodash'
export default {
    props: {
        item: { type: Object },
        initialItem: { type: Object },
        mKey: { type: Number },
        services: {type: Object}
    },
    data() {
        return {
            classes: [
                { text: 'Class I', mapClass: 'CLASSI', value: '1', default_I: 3, default_II: 5},
                { text: 'Class II', mapClass: 'CLASSII', value: '2', default_I: 7, default_II: 10},
                { text: 'Class III', mapClass: 'CLASSIII', value: '3', default_I: 10, default_II: 15},
            ],
            thresholds: [],
            oItem: [],
            orgType: '',
        }
    },
    watch: {
        thresholds: {
            handler(v, v_old) {
                if(this.oItem.length == 0)
                {
                    this.oItem = _.cloneDeep(this.thresholds)
                }
                this.$parent.hasUnsavedChanges = this.compareObjects(v, this.oItem)
                v.forEach((el, i) => {
                    if ( el.val1 < 1 ) {
                        el.val1 = 1
                    }
                    if ( el.val2 < 2 ) {
                        el.val2 = 2
                    }
                    if ( el.val2 <= parseInt(el.val1) + 1 ) {
                        el.val2 = parseInt(el.val1) + 2
                    }
                })
            },
            deep: true
        },
        mKey() {
            this.orgType = this.$store.state.organization.type
            this.$nextTick(()=>{
                this.classes.forEach(cl => {
                    var obj = undefined
                    if (this.item.thresholds) {
                        obj = this.item.thresholds.find(x => {
                            if (x.classification == cl.mapClass && x.type == 'ACKNOWLEDGMENT') {
                                return x
                            } else {
                                return undefined
                            }
                        })
                    }
                    if (obj) {
                        this.thresholds.push({
                            ...obj,
                            classification: cl.value
                        })
                    } else {
                        this.thresholds.push({
                            classification: cl.value,
                            val1: cl.default_I,
                            val2: cl.default_II,
                        })
                    }
                })
            })
        }
    },
    methods: {
        text(threshold) {
            return {
                yellow: (parseInt(threshold.val1) + 1).toString() + ' to ',
                red: '+ ' + (parseInt(threshold.val2) + 1).toString() + ' days'
            }
        },
        save() {
            var payload = { type: 'ACKNOWLEDGMENT'}
            this.thresholds.forEach(el => {
                payload['class'+el.classification+'_val1'] = el.val1
                payload['class'+el.classification+'_val2'] = el.val2
            })

            this.services.updateThreshold(payload)
        },
        compareObjects(obj1, obj2) {
            return JSON.stringify(obj1) !== JSON.stringify(obj2)
        }
    }
}