import emailService from '../../../../../services/provider/email'
import applicationService from '../../../../../services/provider/application'
import tinymce from 'vue-tinymce-editor'
import Vue from 'vue'
import {getUserType} from '../../../../../misc/auth'
Vue.component('tinymce', tinymce)

export default {
    data() {
        return {
            items: null,
            bodyContent: null,
            subject: null,
            userType: getUserType(),
            tinyOptions: {
                'height': 280,
                branding: false,
                statusbar: false,
                nonbreaking_force_tab: true,
                indentation: '30pt',
                content_style: 'p {font-size: 16px;color: #666666;font-family: Helvetica,Arial,sans-serif;}',
                setup: function (ed) {
                    ed.on('keydown', function (evt) {
                        if (evt.keyCode == 9) {
                            ed.execCommand('mceInsertContent', false, '&emsp;&emsp;&emsp;')
                            evt.preventDefault()
                        }
                    })
                }
            },
        }
    },
    created() {
        if(this.userType == 'PROVIDER'){
            this.getEmail('PROVIDER_CENTRALIZED_INTAKE_MAIL')
        }
    },
    methods: {
        getEmail(type) {
            var that = this
            emailService.getEREmail(type).then(resp => {
                if (!resp.error) {
                    that.items = resp.data.d
                    that.bodyContent = /<body.*?>([\s\S]*)<\/body>/.exec(that.items.content)[1]
                    that.subject = that.items.subject
                    that.bodyContent = that.bodyContent.replaceAll('${ProviderName}','<span style="font-weight: 600;">' + this.$store.state.organization.name + '</span>')
                    that.bodyContent = that.bodyContent.replaceAll('<p style="margin-bottom: 0px; margin-top: 0px;">${CentralizedContent}</p>','')
                }
            })
        },
        onCompleted(){

        },
        toggle(){
            this.$refs.btnLaunchModal.click()
        },
        async sendEmailVerification() {
            if(await this.$refs['observer'].validate())
            {
                let formData = new FormData()
                formData.append('email', this.$parent.data.centralized_email)
                formData.append('centralizedcontent', this.bodyContent)
                formData.append('subject', this.subject)
                applicationService.centralizedVerificationEmail(formData)
                    .then((resp) => {
                        if (!resp.error) {

                            this._showToast('Email sent successfully!')
                            this.toggle()
                            this.$parent.disableBtn = true
                            this.$parent.data.centralizedEmailStatus = 'Send'
                        }
                    })
            }
        },
    }
}